
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import { setSampleFile } from '../../reducers/BuildRenderRequestSlice';
import { FileUpload } from "../Common/FileUpload";

export const SampleFileUpload = () => {
    const dispatch = useDispatch();
    const { sampleFile } = useSelector( ( state: RootState ) => state.buildRenderRequest );

    return <FileUpload label={ "Sample File (optional)" } file={ sampleFile } onFileUpdate={ ( file ) => dispatch( setSampleFile( file ) ) } />
};