import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import { setStainColorOverrides } from '../../reducers/BuildRenderRequestSlice';
import { useGetSettingsQuery } from '../../services/chromosomeApi';
import { ColorSelect } from "../Common/ColorSelect";


export const BandStains = () => {
    const dispatch = useDispatch();
    const { isLoading, data } = useGetSettingsQuery();
    const { stainColorOverrides } = useSelector( ( state: RootState ) => state.buildRenderRequest );
    return isLoading ? <CircularProgress /> :
        <ColorSelect
            selectedColors={ stainColorOverrides }
            colors={ data?.bandStains || [] }
            colorChanges={ ( colors ) => dispatch( setStainColorOverrides( colors ) ) } />

}