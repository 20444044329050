import { InfoRounded } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import { setPreviewOnly } from '../../reducers/RenderRequestSlice';

export const PreviewCheckBox = () => {
    const dispatch = useDispatch();
    const { isPreviewOnly } = useSelector( ( state: RootState ) => state.renderRequest );
    return <FormControlLabel
        checked={ isPreviewOnly }
        control={ <Checkbox /> }
        onChange={ ( _, checked ) => dispatch( setPreviewOnly( checked ) ) }
        label={ <>
            <b>Preview Only</b>
            <Tooltip describeChild title="Preview the rendering without downloading the file.">
                <InfoRounded color="info" />
            </Tooltip>
        </>
        } />
};