import { FormControlLabel, Input } from "@mui/material";
import React from "react";

export interface FileUploadProps {
    label: string;
    file?: File;
    onFileUpdate: ( file: File ) => void;
}
const styles = {
    alignItems: 'flex-start',
    asterisk: {
        color: '#db3131',
        '&$error': {
            color: '#db3131'
        },
    }

};
export const FileUpload = ( { label, file, onFileUpdate }: FileUploadProps ) => {

    const fileChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        if ( e.currentTarget.files ) {
            let file = e.currentTarget.files[0];
            onFileUpdate( file );
        }
    };

    return <FormControlLabel
        sx={ styles }
        label={ <b>{ label }{ file?.name }</b> }
        labelPlacement='bottom'
        control={ < Input type='file' onChange={ fileChange } /> }
    />
};