import { CircularProgress, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import { setFileFormat } from '../../reducers/BuildRenderRequestSlice';
import { useGetSettingsQuery } from '../../services/chromosomeApi';

export const DownloadFormats = () => {
    const dispatch = useDispatch();
    const { fileFormat } = useSelector( ( state: RootState ) => state.buildRenderRequest );
    const { isLoading, data } = useGetSettingsQuery();
    return <><RadioGroup
        row>
        { isLoading ? <CircularProgress /> :
            ( data?.availableFileFormats || [] )
                .map( format =>
                    <FormControlLabel
                        label={ format.name }
                        defaultChecked={ format.value === 'Png' }
                        value={ format.value }
                        onChange={ ( e, checked ) => { dispatch( setFileFormat( format.value ) ) } }
                        control={ <Radio checked={ fileFormat === format.value } /> } /> )
        }
    </RadioGroup>
        <Typography sx={ { textDecoration: 'italics', color: 'red', fontSize: '12pt' } }>**Only PNG is support right now!
        </Typography>
    </>;

}