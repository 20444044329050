import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useDispatch1,
  useSelector as useSelector1,
} from "react-redux";
import BuildRenderRequestSlice from "./reducers/BuildRenderRequestSlice";
import renderRequestSlice from "./reducers/RenderRequestSlice";
import { chromosomeApi } from "./services/chromosomeApi";
const reducer = {
  [chromosomeApi.reducerPath]: chromosomeApi.reducer,
  [renderRequestSlice.name]: renderRequestSlice.reducer,
  [BuildRenderRequestSlice.name]: BuildRenderRequestSlice.reducer,
};

const store = configureStore( {
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  reducer: reducer,
  middleware: ( getDefaultMiddleware ) =>
    getDefaultMiddleware( { serializableCheck: false } ).concat(
      chromosomeApi.middleware,
    ),
} );
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useDispatch = useDispatch1;
export const useSelector = useSelector1;

export const reducers = reducer;
export default store;
