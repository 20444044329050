import {FileCopyTwoTone, FileUploadTwoTone, FilterListTwoTone} from "@mui/icons-material";
import LineStyleSharpIcon from '@mui/icons-material/LineStyleSharp';
import TroubleshootTwoToneIcon from '@mui/icons-material/TroubleshootTwoTone';
import {Stack} from "@mui/material";
import React from "react";
import {DownloadExampleFiles} from "./Common/DownloadExampleFiles";
import {Item} from "./Common/Item";
import {SideItem} from "./Common/SideItem";
import {AbnormalityFilter} from "./Settings/AbnormalityFilter";
import {AbnormalityStains} from "./Settings/AbnormalityStains";
import {BandsFileUpload} from "./Settings/BandsFileUpload";
import {BandStains} from "./Settings/BandStains";
import {ChromosomeFilter} from "./Settings/ChromosomeFilter";
import {DownloadFormats} from "./Settings/DownloadFormats";
import {GenesFileUpload} from "./Settings/GenesFileUpload";
import {HideUnusedAbnormalityTypesCheckBox} from "./Settings/HideUnusedAbnormalityTypesCheckBox";
import {PreviewCheckBox} from "./Settings/PreviewCheckbox";
import {RenderButton} from "./Settings/RenderButton";
import {SampleFileUpload} from "./Settings/SampleFileUpload";
import {TopInfo} from "./Settings/TopInfo";



export const SidePanelLeft = () => {
    return <Stack spacing={ 1 } maxHeight={ '60%' } >
        <div>
            <TopInfo />
        </div>
        <SideItem title={ <>File Uploads<span style={ { marginLeft: '5px', fontSize: '10pt', color: 'red' } }>(Required)</span></> } keepOpen icon={ <FileUploadTwoTone htmlColor='blue' /> }>
            <Stack  spacing={2}>
                <Item><BandsFileUpload /></Item>
                <Item><SampleFileUpload /></Item>
                <Item><GenesFileUpload /></Item>
                <DownloadExampleFiles />
            </Stack>
        </SideItem>
        <div>
            <RenderButton />
        </div>
        <div>
            <Stack direction={ "column" }>
                <PreviewCheckBox />
                <HideUnusedAbnormalityTypesCheckBox />
            </Stack>
        </div>
        <SideItem title="File Formats"  icon={ <FileCopyTwoTone htmlColor='gray' /> }>
            <DownloadFormats />
        </SideItem>

        <SideItem title="Abnormality Colors" icon={ <TroubleshootTwoToneIcon htmlColor='green' /> } >
            <AbnormalityStains />
        </SideItem>
        <SideItem title="Band Stain Colors" icon={ <LineStyleSharpIcon htmlColor='blue' /> }        >
            <BandStains />
        </SideItem>
        <SideItem title="Chromosome Filter" icon={ <FilterListTwoTone htmlColor='orange' /> }>
            <ChromosomeFilter />
        </SideItem>
        <SideItem title="Abnormality Filter" icon={ <FilterListTwoTone htmlColor='red' /> }>
            <AbnormalityFilter />
        </SideItem>
    </Stack >;
};