import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import React from "react";

export interface SideItemProps {
    title: React.ReactElement | string;
    icon?: React.ReactElement;
    children?: React.ReactNode;
    keepOpen?: boolean;
}

export const SideItem = ( { title, icon, children, keepOpen }: SideItemProps ) => {
    return <Accordion expanded={ keepOpen }  >
        <AccordionSummary expandIcon={ <ExpandMoreIcon style={ { visibility: keepOpen ? 'hidden' : 'visible' } } /> }  >
            <Stack direction={ 'row' }
                spacing={ 1 }>
                { icon }<Typography variant='h5'>{ title }</Typography>
            </Stack>
        </AccordionSummary>
        <AccordionDetails sx={ { maxHeight: 400, overflowY: 'auto' } } >
            { children }
        </AccordionDetails>
    </Accordion >;

}