import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import knownAbnormalityTypes from '../../constants/AbnormalityTypes';

import { setExcludedAbnormalityTypes } from '../../reducers/BuildRenderRequestSlice';



export const AbnormalityFilter = () => {
    const dispatch = useDispatch();
    const { excludedAbnormalityTypes } = useSelector( ( state: RootState ) => state.buildRenderRequest );

    const onAbnormalityTypeChange = ( addToExcluded: boolean, chromosome: string ) => {
        let newAbnormalityTypes = excludedAbnormalityTypes.filter( c => c !== chromosome );
        if ( addToExcluded ) {
            newAbnormalityTypes = [...excludedAbnormalityTypes, chromosome];
        }
        dispatch( setExcludedAbnormalityTypes( newAbnormalityTypes ) );
    };

    const toggleSelectAll = ( checkedSelectAll: boolean ) => {
        dispatch( setExcludedAbnormalityTypes( checkedSelectAll ? [] : knownAbnormalityTypes ) );
    };

    return <Grid container
        spacing={ 2 }
        columns={ { xs: 4, sm: 8, md: 12 } }
    >
        { knownAbnormalityTypes.map( ( c, index ) =>

            <Grid item >
                <FormControlLabel

                    label={ `${c.replace( '_', ' ' )}` }
                    control={ <Checkbox
                        onChange={ ( _, checked ) => onAbnormalityTypeChange( !checked, c ) }
                        checked={ !excludedAbnormalityTypes.some( e => e === c ) }
                    /> } />
            </Grid>
        ) }
        <Grid item>
            <FormControlLabel

                label={ `Select All` }
                control={ <Checkbox
                    onChange={ ( _, checked ) => toggleSelectAll( checked ) }
                    checked={ excludedAbnormalityTypes.length === 0 }
                /> } />
        </Grid>
    </Grid>;



};