import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RenderRequestModel } from "src/models/RenderRequestModel";
import { SettingsModel } from "src/models/SettingsModel";

const emptyRenderRequest: RenderRequestModel = {
  abnormalColorOverrides: [],
  stainColorOverrides: [],
  fileFormat: "Png",
  excludedChromosomes: [],
  excludedAbnormalityTypes: []
};
const BuildRenderRequestSlice = createSlice( {
  initialState: emptyRenderRequest,
  name: "buildRenderRequest",
  reducers: {
    setExcludedChromosomes: ( draft, action: PayloadAction<Array<string>> ) => {
      draft.excludedChromosomes = action.payload;
    },
    setExcludedAbnormalityTypes: ( draft, action: PayloadAction<Array<string>> ) => {
      draft.excludedAbnormalityTypes = action.payload;
    },
    setAbnormalColorOverrides: (
      draft,
      action: PayloadAction<Array<SettingsModel>>,
    ) => {
      draft.abnormalColorOverrides = action.payload;
    },
    setStainColorOverrides: (
      draft,
      action: PayloadAction<Array<SettingsModel>>,
    ) => {
      draft.stainColorOverrides = action.payload;
    },
    setCytoBandFile: ( draft, action: PayloadAction<File> ) => {
      draft.cytoBandFile = action.payload;
    },
    setSampleFile: ( draft, action: PayloadAction<File> ) => {
      draft.sampleFile = action.payload;
    },
    setGenesFile: ( draft, action: PayloadAction<File> ) => {
      draft.genesFile = action.payload;
    },
    setFileFormat: ( draft, action: PayloadAction<string> ) => {
      draft.fileFormat = action.payload;
    },
    setHideUnusedAbnormalityTypes: ( draft, action: PayloadAction<boolean> ) => {
      draft.hideUnusedAbnormalityTypes = action.payload;
    },
  },
} );

export const {
  setAbnormalColorOverrides,
  setCytoBandFile,
  setExcludedChromosomes,
  setFileFormat,
  setSampleFile,
  setStainColorOverrides,
  setHideUnusedAbnormalityTypes,
  setExcludedAbnormalityTypes,
  setGenesFile
} = BuildRenderRequestSlice.actions;
export default BuildRenderRequestSlice;
