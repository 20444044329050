import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import FileSaver from "file-saver";
import {RootState} from "src/store";
import {resolveBaseUrl} from "../Utils/urlHelper";
import ReactGA from 'react-ga4';
ReactGA.initialize('G-SKNP5N337Q');

const endpoint = `${resolveBaseUrl()}chromosome/generate-ideogram`;

export interface RenderResponse {
  fileBlob?: Blob;
  renderState: 'running' | 'initial' | 'complete';
  isPreviewOnly: boolean;
  actualFormat?: string
  error?: string | null;
}
const emptyRenderResponse: RenderResponse = {
  renderState: 'initial',
  isPreviewOnly: true,
};

export const asyncRequestRendering = createAsyncThunk(
  endpoint,
  async (_: void, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const renderRequestModel = state.buildRenderRequest;
    const formData = new FormData();
    (renderRequestModel.abnormalColorOverrides || []).forEach((x, index) => {
      formData.append(`abnormalColorOverrides[${index}].name`, x.name);
      formData.append(`abnormalColorOverrides[${index}].value`, x.value);
    });

    (renderRequestModel.stainColorOverrides || []).forEach((x, index) => {
      formData.append(`stainColorOverrides[${index}].name`, x.name);
      formData.append(`stainColorOverrides[${index}].value`, x.value);
    });

    (renderRequestModel.excludedChromosomes || []).forEach((x, index) => {
      formData.append(`excludedChromosomes[${index}]`, x);
    });
    (renderRequestModel.excludedAbnormalityTypes || []).forEach((x, index) => {
      formData.append(`excludedAbnormalityTypes[${index}]`, x);
    });
    formData.append("fileFormat", renderRequestModel.fileFormat);
    if(renderRequestModel.cytoBandFile) {
      formData.append("cytoBandFile", renderRequestModel.cytoBandFile);
    }
    if(renderRequestModel.sampleFile) {
      formData.append("sampleFile", renderRequestModel.sampleFile);
    }
    if(renderRequestModel.genesFile) {
      formData.append("genesFile", renderRequestModel.genesFile);
    }
    if(renderRequestModel.hideUnusedAbnormalityTypes) {
      formData.append("hideUnusedAbnormalityTypes", Boolean(renderRequestModel.hideUnusedAbnormalityTypes).toString());
    }
    ///ajax post
    try {

      const response = await axios.postForm(endpoint, formData, {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      ReactGA.send({hitType: "render", page: endpoint, title: "Render Ideogram"});
      return thunkApi.fulfillWithValue(response.data as Blob);
    } catch(result: any) {
      const errorMessage = await result?.response?.data.text();
      return thunkApi.rejectWithValue(errorMessage);
    }

  }
);

const renderRequestSlice = createSlice({
  initialState: emptyRenderResponse,
  name: "renderRequest",
  reducers: {
    setPreviewOnly: (draft, action: PayloadAction<boolean>) => {
      draft.isPreviewOnly = action.payload;
    },
    setError: (draft, action: PayloadAction<string | null>) => {
      draft.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(asyncRequestRendering.fulfilled, (state, action) => {

      state.fileBlob = action.payload;
      const {type} = state.fileBlob;
      let fileExtension = type.split("/")[1];
      state.actualFormat = fileExtension;
      if(!state.isPreviewOnly && state.fileBlob) {
        FileSaver(state.fileBlob, `ideogram.${fileExtension}`);
      }
      state.error = null;
      state.renderState = 'complete';
    });
    builder.addCase(asyncRequestRendering.pending, (state, action) => {
      state.renderState = 'running';
    });
    builder.addCase(asyncRequestRendering.rejected, (state, action: any) => {
      // Add user to the state array
      state.error = action?.payload ? JSON.parse(action?.payload)?.error : '';
      state.renderState = 'complete';
    });
  },
});

export const {setPreviewOnly, setError} = renderRequestSlice.actions;
export default renderRequestSlice;
