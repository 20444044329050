import {Button} from "@mui/material";
import React from "react";
import {AppDispatch,RootState,useDispatch,useSelector} from "src/store";
import {asyncRequestRendering} from '../../reducers/RenderRequestSlice';


export const RenderButton = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { renderState } = useSelector( ( state: RootState ) => state.renderRequest );
    const { cytoBandFile } = useSelector( ( state: RootState ) => state.buildRenderRequest );
    return <Button
        fullWidth
        color="primary"
        variant="contained"
        disabled={ !( Boolean( cytoBandFile ) || renderState === 'running' ) }
        onClick={ () => dispatch( asyncRequestRendering() ) }
    >Render</ Button>;
};