
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SettingsState } from 'src/models/SettingsState';
import { resolveBaseUrl } from './../Utils/urlHelper';
const baseUrl = resolveBaseUrl();
export const chromosomeApi = createApi( {
    reducerPath: 'chromosomeApi',
    baseQuery: fetchBaseQuery( { baseUrl } ),
    refetchOnMountOrArgChange: 3,
    endpoints: ( builder ) => ( {
        getSettings: builder.query<SettingsState, void>( {
            query: () => `chromosome/settings`
        } ),
        postRenderRequest: builder.query<FormData, void>( {
            query: () => `chromosome/render`
        } )
    } )
} );

export const { endpoints, useGetSettingsQuery } = chromosomeApi;