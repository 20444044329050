import { Stack, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { SettingsModel } from "src/models/SettingsModel";
import { ColorBox } from "./ColorBox";
import { ColorBoxEdit } from './ColorBoxEdit';

export interface ColorSelectProps {
    colors: Array<SettingsModel>;
    selectedColors: Array<SettingsModel>;
    colorChanges: ( colorSettings: Array<SettingsModel> ) => void;
}

export const ColorSelect = ( { colors, selectedColors, colorChanges }: ColorSelectProps ) => {
    //const [editedColors, setEditedColors] = useState<Array<SettingsModel>>( [] );
    const addColor = ( colorModel: SettingsModel ) => {
        let newColors = selectedColors.filter( c => c.name !== colorModel.name );
        if ( colorModel.value !== '' ) {
            newColors = [...newColors, colorModel];
        }
        colorChanges( newColors );
    };

    return <TableContainer >
        <TableHead>
            <TableRow>

                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Current Color</TableCell>
                <TableCell align="left">Override Hex Value</TableCell>
            </TableRow>
        </TableHead>

        <TableBody>
            { colors.map( ( colorModel ) => (
                <TableRow
                    key={ colorModel.name }
                    sx={ { '&:last-child td, &:last-child th': { border: 0, maxHeight: "50px", overflow: 'hidden', width: 200 } } }
                >

                    <TableCell align="left">{ colorModel.name }</TableCell>
                    <TableCell align="left">
                        <Stack direction='column' spacing={ 2 }>
                            <ColorBox colorModel={ colorModel } />
                        </Stack>
                    </TableCell>
                    <TableCell align="left" >
                        <ColorBoxEdit
                            colorModel={ { ...colorModel, value: selectedColors.find( c => c.name === colorModel.name )?.value || '' } }
                            saveColor={ ( cm ) => addColor( cm ) }
                        />
                    </TableCell>
                </TableRow>
            ) ) }
        </TableBody >
    </TableContainer >
};