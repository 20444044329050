import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { RootState, useSelector } from "src/store";

export const RenderingLoader = () => {
    const { renderState } = useSelector( ( state: RootState ) => state.renderRequest );

    return <Backdrop
        sx={ { color: '#fff', zIndex: ( theme ) => theme.zIndex.drawer + 1 } }
        open={ renderState==='running' }
    >
        <CircularProgress color="inherit" />
    </Backdrop>;
}